import { isObject } from '../types/common';

export function objectMap<T, V, K extends string | number | symbol>(
  object: Record<K, T>,
  cb: (value: T, key?: K) => V
): Record<K, V> {
  if (isObject(object) && typeof cb === 'function') {
    return (Object.keys(object) as K[]).reduce((acc, key) => {
      acc[key] = cb(object[key], key);
      return acc;
    }, {} as Record<K, V>);
  }
  return object as never;
}

export function objectFilter<T, K extends string | number | symbol>(
  object: Partial<Record<K, T>>,
  cb: (value: T, key?: K) => boolean
): Partial<Record<K, T>> {
  if (isObject(object) && typeof cb === 'function') {
    return (Object.keys(object) as K[]).reduce((acc, key) => {
      if (cb(object[key], key)) {
        acc[key] = object[key];
      }
      return acc;
    }, {} as Record<K, T>);
  }
  return object as never;
}

export const compareObjects = (
  a: unknown,
  b: unknown,
  excludeProperties: string[] = []
): boolean => {
  if (a === b) {
    return true;
  }
  if (typeof a !== typeof b) {
    return false;
  }
  if (!isObject(a) || !isObject(b)) {
    return false;
  }
  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);

  excludeProperties.forEach(prop => {
    const aIndex = aKeys.indexOf(prop);
    if (aIndex !== -1) {
      aKeys.splice(aIndex, 1);
    }
    const bIndex = bKeys.indexOf(prop);
    if (bIndex !== -1) {
      bKeys.splice(bIndex, 1);
    }
  });

  if (aKeys.length !== bKeys.length) {
    return false;
  }
  return aKeys.every(key => {
    let isEqual = false;
    if (Array.isArray(a[key]) && Array.isArray(b[key])) {
      isEqual = compareArrays(a[key] as unknown[], b[key] as unknown[]);
    } else if (isObject(a[key]) && isObject(b[key])) {
      isEqual = compareObjects(a[key], b[key], excludeProperties);
    } else {
      isEqual = a[key] === b[key];
    }
    return key in b && isEqual;
  });
};

export const compareArrays = (
  a: unknown[],
  b: unknown[],
  excludeProperties: string[] = []
): boolean => {
  if (a === b) {
    return true;
  }
  if (!Array.isArray(a) || !Array.isArray(b)) {
    return false;
  }
  if (a?.length !== b?.length) {
    return false;
  }
  return a.every((value, index) => {
    return compareObjects(value, b[index], excludeProperties);
  });
};

export const toNumeric = (
  value: string | number,
  removeString?: boolean
): string | number => {
  if (typeof value === 'number') {
    return value;
  } else if (removeString) {
    const numericValue = +value.replace(/[^0-9.-]/g, '');
    return isNaN(numericValue) ? 0 : numericValue;
  } else {
    const numericValue = +value;
    return isNaN(numericValue) ? value : numericValue;
  }
};

// fields

export const getSelectorItemValue = <T>(item: { value: T } | T): T => {
  return item && typeof item === 'object' && 'value' in item
    ? item.value
    : item;
};
